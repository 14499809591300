import Messaging from "./Messaging";
import Session from "./Session";
import Widget from "./Widget";

export const WIDGET_S3_BUCKET_URL = process.env.REACT_APP_WIDGET_S3_BUCKET_URL ?? "";
export const WIDGET_IFRAME_CONTENT_ID = "prometeo-widget-iframe-content";
export const WIDGET_IFRAME_CONTAINER_ID = "prometeo-widget-iframe";

/**
 * Create widget instance and insert it in body, then return it for manipulation purposes.
 * @param widgetKey Widget public key as provided in Prometeo dashboard.
 * @param paymentIntentId Payment intent id created before initializing the widget.
 * @param isPayWithLink Allow to know if widget is open with Payment Link.
 * @returns Widget instance which exposes necessary methods to manipulate it.
 */
const init = (widgetKey: string, paymentIntentId?: string | undefined, isPayWithLink?: boolean | undefined) => {
    // Create iframe element and assign parameters.
    var iframe = document.createElement("iframe");
    iframe.id = WIDGET_IFRAME_CONTENT_ID;
    iframe.src = "about:blank";
    iframe.allowFullscreen = true;
    iframe.sandbox.add("allow-scripts");
    iframe.sandbox.add("allow-forms");
    iframe.sandbox.add("allow-same-origin");
    iframe.sandbox.add("allow-modals");
    iframe.sandbox.add("allow-popups");
    iframe.sandbox.add("allow-downloads");
    // iframe.sandbox.add("allow-popups-to-escape-sandbox");

    // Apply styles to iframe and container.
    var iframeContainer = document.createElement("div"); // Overlay effect container.
    iframeContainer.id = WIDGET_IFRAME_CONTAINER_ID;

    if (isPayWithLink) {
        Object.assign(iframeContainer.style, {
            "display": "none" /* Hidden by default */,
            "width": "100%" /* Full width (cover the whole page) */,
            "height": "100%" /* Full height (cover the whole page) */,
            "cursor": "pointer" /* Add a pointer on hover */,
            "justify-content": "center",
            "align-items": "center",
            "overflow-y": "scroll",
        });
    } else {
        Object.assign(iframeContainer.style, {
            position: "fixed" /* Sit on top of the page content */,
            display: "none" /* Hidden by default */,
            width: "100%" /* Full width (cover the whole page) */,
            height: "100%" /* Full height (cover the whole page) */,
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            "background-color": "rgba(0,0,0,0.5)" /* Black background with opacity */,
            "z-index":
                "2" /* Specify a stack order in case you're using a different order for other elements */,
            cursor: "pointer" /* Add a pointer on hover */,
            "justify-content": "center",
            "align-items": "center",
            "overflow-y": "scroll",
        });
    }
    Object.assign(iframe.style, {
        width: "345px",
        height: "568px",
        border: "0",
        margin: "auto",
    });

    iframeContainer.appendChild(iframe);

    const main = document.querySelector('#main');
    if (main && isPayWithLink) {
        main.appendChild(iframeContainer)
    } else {
        document.body.appendChild(iframeContainer);
    }

    // Create Widget object and return it.
    const widget = new Widget(iframeContainer, iframe, widgetKey, paymentIntentId, isPayWithLink);
    widget.registerMessageListener();
    return widget;
};

export { Messaging, Session, Widget, init };

export default init;
