import Messaging from "./Messaging";

export default class Session {
    iframe: HTMLIFrameElement;

    constructor(iframe: HTMLIFrameElement, private sessionKey: string) {
        this.iframe = iframe;
    }

    getOwnerInfo() {
        Messaging.postMessageToFrame(this.iframe, Messaging.GET_OWNER_INFO);
    }

    getAccounts() {
        Messaging.postMessageToFrame(this.iframe, Messaging.GET_ACCOUNTS);
    }

    getSessionKey(): string {
        return this.sessionKey;
    }
}
