// Centralize interfaces used by the init module to avoid bundling entire app.

export interface IUsageParameters {
    allowedFeatureLevel: modulesEnum;
    paymentIntent?: string;
    amount?: number;
    currency?: string;
    dynamicAmount?: boolean;
    dynamicTimer?: number;
    isPreview?: boolean;
    concept?: string;
    externalId?: string;
    bankCodes?: string[];
}

export const enum modulesEnum {
    Login = 1,
    Payments,
}

export interface IThemeParameters {
    brandName?: string;
    primaryColor?: string;
    secondaryColor?: string;
    logoUrl?: string;
    showPrometeoLogo?: boolean;
    customStyles?: CustomStyles;
    isMobile?: boolean;
    isBrandLogoMain?: boolean;
}

export interface CustomStyles {
    [x: string]: string | boolean;
}
